import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import twitter from "../../images/react-icons/twitter white logo.svg"
import "../event-details-page/EventSlider.css"
import * as styles from "./EventDetails.module.scss"
import "./EventDetails.scss"

const EventDetaisl = ({ strapiData, upcomingCTA, setOpen }) => {
  const settings = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 200,
    arrows: true,
    lazyLoad: "ondemand",
    responsive: [],
  }

  function loadScript(src) {
    return new Promise(function (resolve, reject) {
      var s
      s = document.createElement("script")
      s.defer = true
      s.async = true
      s.src = src
      s.onload = resolve
      s.onerror = reject
      document.head.appendChild(s)
    })
  }

  React.useEffect(() => {
    const event_Slider = document?.getElementsByClassName("event_slider")
    const eventSlider = document?.getElementById("eventSlider_id")
    if (eventSlider) {
      eventSlider.innerHTML = event_Slider[0]?.innerHTML
      eventSlider.style.display = "unset"
    }
  }, [])

  return (
    <div className={styles.eventDetails}>
      <Container>
        <Row>
          <Col xl={2} lg={12}>
            <div className={`${styles.socials} Socila-share`}>
              <FacebookShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                quote={strapiData.seo.metaDescription}
                title={strapiData.seo.metaTitle}
                hashtag={[]}
              >
                <FacebookIcon borderRadius={5} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                title={strapiData.seo.metaTitle}
                summary={strapiData.seo.metaDescription}
                source=""
              >
                <LinkedinIcon borderRadius={5} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                title={strapiData.seo.metaTitle}
                via=""
                hashtags={[]}
              >
                <div
                  style={{
                    height: "64px",
                    width: "64px",
                    justifyContent: "center",
                    display: "flex",
                    background: "#1e9cf0",
                    borderRadius: "6px",
                    margin: "auto",
                  }}
                >
                  <img
                    src={twitter}
                    borderRadius={5}
                    decoding="async"
                    loading="lazy"
                    alt="twitter"
                    style={{ width: "32px" }}
                  />
                </div>
              </TwitterShareButton>
              <RedditShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                title={strapiData.seo.metaTitle}
              >
                <RedditIcon borderRadius={5} />
              </RedditShareButton>
              <EmailShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                title={strapiData.seo.metaTitle}
                body={strapiData.seo.metaDescription}
              >
                <EmailIcon borderRadius={5} />
              </EmailShareButton>
              <WhatsappShareButton
                url={`www.invozone.com/events/${strapiData?.slug}`}
                title={strapiData.seo.metaTitle}
              >
                <WhatsappIcon borderRadius={5} />
              </WhatsappShareButton>
            </div>
          </Col>
          <Col xl={7} lg={12}>
            <div className={styles.heading}>
              <h1>{strapiData?.title}</h1>
              <div id="kk"></div>
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.rawDesc,
                }}
              />

              <div className="event_slider">
                <div
                  id="carouselExampleControls"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <div class="carousel-inner">
                    {strapiData?.eventImages?.map((e, i) => (
                      <div class={`${i === 0 && "active"} carousel-item `}>
                        <img
                          class="d-block w-100"
                          decoding="async"
                          loading="lazy"
                          src={e?.localFile?.publicURL}
                          alt={e?.alternativeText || e?.caption}
                        />
                        <p>{e?.caption}</p>
                      </div>
                    ))}
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={12}>
            <div className={styles.meetup}>
              <img
                decoding="async"
                loading="lazy"
                src={upcomingCTA?.featuredImage?.localFile?.publicURL}
                alt={upcomingCTA?.title}
              />
              <h4>{upcomingCTA?.title}</h4>
              <h3>{upcomingCTA?.subTitle}</h3>
              <div className={styles.meetupBtn}>
                <Button
                  variant="light "
                  style={{ fontSize: "12px" }}
                  className="btn_white2_border"
                  onClick={() => setOpen(true)}
                >
                  {upcomingCTA?.buttons[0]?.title}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.eventImages} style={{ cursor: "unset" }}></div>
      </Container>
    </div>
  )
}

export default EventDetaisl
